import axios from 'axios'
import moment from 'moment'
import env from '../../env'
import db from './db'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchCorporatesFromCHM(ctx) {
      const body = {
        username: env.chmApp.chmUser,
        password: env.chmApp.chmPassword,
      }
      axios({ url: `${env.chmApp.chmUrl}/auth/get-access-token`, baseURL: '', method: 'post', data: body })
        .then(response => {
          // fetch corporates
          axios({
            url: `${env.chmApp.chmUrl}/corporates/`,
            baseURL: env.chmUrl,
            method: 'get',
            headers: { Authorization: `Bearer ${response.data.access_token}` },
            data: { limit: 400 },
          })
            .then(response => {
              // add corporates to db
              db.corporates.bulkAdd(response.data)
            })
            .catch(error => {
              console.log(error)
            })
        })
        .catch(error => {
          console.log(error)
        })
    },
    fetchCorporatesFromDB(ctx) {
      return new Promise((resolve, reject) => {
        db.corporates
          .toArray()
          .then(corps => {
            resolve(corps)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchVaccineRequests(ctx, params) {
      return new Promise((resolve, reject) => {
        db.corporates
          .toArray()
          .then(corps => {
            // fetch vaccine requests for all corporates
            axios
              .get(`${env.chmApp.baseUrl}/api/v1/vaccine-request`, { params })
              .then(response => {
                let processed = []
                let raw = response.data['result']
                // clean data for display
                for (let i = 0; i < raw.length; i++) {
                  const item = raw[i]
                  // match with corporate
                  const corp = corps.find(corp => corp.uuid === item.corporate['legacy_id'])
                  if (corp) {
                    item.corporate_name = corp.name
                    item.location = corp.town
                  }
                  // match with vaccine request type
                  if (item.is_csr) {
                    item.request_type = 'CSR'
                  } else {
                    item.request_type = 'Internal Booking'
                  }
                  // match with status
                  if (item.status !== null) {
                    item.status_name = item.status['name']
                  }
                  // match with date booked
                  item.booked_on = moment(item.created_at).format('DD/MM/YYYY')

                  processed.push(item)
                }
                resolve(processed)
              })
              .catch(error => {
                console.log(error)
                reject(error)
              })
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchCorpById(ctx, id) {
      return new Promise((resolve, reject) => {
        db.corporates
          .where('uuid')
          .equalsIgnoreCase(id)
          .toArray()
          .then(corps => {
            resolve(corps[0])
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchVaccineRequestByCorpId(ctx, id) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${env.chmApp.baseUrl}/api/v1/vaccine-request`, { params: { legacy_id: id } })
          .then(response => {
            let processed = []
            let raw = response.data['result']
            // clean data for display
            for (let i = 0; i < raw.length; i++) {
              const item = raw[i]

              // match with vaccine request type
              if (item.is_csr) {
                item.request_type = 'CSR'
              } else {
                item.request_type = 'Internal Booking'
              }
              // match with status
              if (item.status !== null) {
                item.status_name = item.status['name']
              }
              // match with date booked
              item.booked_on = moment(item.created_at).format('DD/MM/YYYY')
              processed.push(item)
            }
            resolve(processed)
          })
          .catch(error => {
            console.log(error)
            reject(error)
          })
      })
    },
    fetchGroups(ctx) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${env.chmApp.baseUrl}/api/v1/group`)
          .then(response => {
            resolve(response.data['result'])
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    findBranches(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .get(`${env.chmApp.baseUrl}/api/v2/group?facility_id=${payload.facilityId}&radius=${payload.radius}`)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    createMohGroup(ctx, payload) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${env.chmApp.baseUrl}/api/v1/group`, payload)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
  },
}

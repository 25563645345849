const reports = [
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/Reports.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports/charts/facility',
    name: 'reports-charts-facility',
    component: () => import('@/views/reports/charts/FacilitySummary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports/charts/corporates',
    name: 'reports-charts-corporates',
    component: () => import('@/views/reports/charts/FacilitySummary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports/graphs/facility',
    name: 'reports-graphs-facility',
    component: () => import('@/views/reports/graphs/FacilitySummary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports/graphs/corporates',
    name: 'reports-graphs-corporates',
    component: () => import('@/views/reports/graphs/FacilitySummary.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/reports/maps',
    name: 'reports-maps',
    component: () => import('@/views/reports/Maps.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default reports

// eslint-disable-next-line object-curly-newline
import { mdiBadgeAccountHorizontal, mdiDomainPlus, mdiHomeOutline, mdiMonitorDashboard } from '@mdi/js'

export default [
  {
    title: 'Dashboards',
    icon: mdiHomeOutline,
    children: [
      {
        icon: mdiMonitorDashboard,
        title: 'KEPSA/Home',
        to: 'dashboard-home',
      },
      {
        icon: mdiDomainPlus,
        title: 'Facilities',
        to: 'dashboard-facility',
      },
      {
        icon: mdiBadgeAccountHorizontal,
        title: 'Corporates',
        to: 'dashboard-corporates',
      },
    ],
  },
]

const dashboard = [
  {
    path: '/dashboard/kepsa',
    name: 'dashboard-home',
    component: () => import('@/views/dashboards/crm/Crm.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboard/facilities',
    name: 'dashboard-facility',
    component: () => import('@/views/dashboards/analytics/Analytics.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/dashboards/corporates',
    name: 'dashboard-corporates',
    component: () => import('@/views/dashboards/ecommerce/Ecommerce.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default dashboard

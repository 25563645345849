// eslint-disable-next-line object-curly-newline
import { mdiDotsHorizontal, mdiFileDocumentOutline } from '@mdi/js'

export default [
  {
    title: 'Others',
    icon: mdiDotsHorizontal,
    children: [
      {
        title: 'Documentation',
        icon: mdiFileDocumentOutline,
        to: 'documentation',
      },
    ],
  },
]

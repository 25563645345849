import '@/@fake-db/db'
import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import configurations from './config'
import textTransform from './mixins/textTransform'
import './plugins/acl'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'

Vue.config.productionTip = false
Vue.use(configurations)
Vue.mixin(textTransform)

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')

const mohRequests = [
  {
    path: '/healthke-requests',
    name: 'moh-requests',
    component: () => import('@/views/moh-requests/MohRequests.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/healthke-requests/:id',
    name: 'moh-requests-view',
    component: () => import('@/views/moh-requests/showMohRequests.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default mohRequests

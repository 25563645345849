import { mdiMapbox, mdiMapMarkerDistance, mdiMapMarkerPath } from '@mdi/js'

export default [
  {
    title: 'Groups',
    icon: mdiMapMarkerDistance,
    children: [
      {
        title: 'View Groups',
        icon: mdiMapbox,
        to: 'groups-list',
      },
      {
        title: 'MOH Requests',
        icon: mdiMapMarkerPath,
        to: 'moh-requests',
      },
    ],
  },
]

const facility = [
  {
    path: '/facilities',
    name: 'facility-list',
    component: () => import('@/views/facilities/list/FacilityList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/facilities/:id',
    name: 'facility-view',
    component: () => import('@/views/facilities/FacilityView.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/inventory',
    name: 'facility-allocation',
    component: () => import('@/views/inventory/list/InventoryList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/inventory/:id',
    name: 'facility-allocation-view',
    component: () => import('@/views/inventory/view/InventoryView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default facility

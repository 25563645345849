import { mdiBadgeAccountAlert, mdiBadgeAccountHorizontal, mdiCalendarClockOutline } from '@mdi/js'

export default [
  {
    title: 'Corporates',
    icon: mdiBadgeAccountHorizontal,
    children: [
      {
        title: 'All Corporates',
        icon: mdiBadgeAccountAlert,
        to: 'corporates-list',
      },
      {
        title: 'Vaccine Requests',
        icon: mdiCalendarClockOutline,
        to: 'corporates-requests',
      },
    ],
  },
]

// axios
import axios from 'axios'
import Vue from 'vue'
import env from '../env'

const axiosIns = axios.create({
  baseURL: `${env.chmApp.baseUrl}${env.chmApp.version}`,
  timeout: 100000,
})

axiosIns.interceptors.request.use(
  config => {
    const accessToken = localStorage.getItem('accessToken')
    if (accessToken) config.headers.Authorization = `Bearer ${accessToken}`

    return config
  },
  error => Promise.reject(error),
)

Vue.prototype.$http = axiosIns

export default axiosIns

const groups = [
  {
    path: '/vaccination-groups',
    name: 'groups-list',
    component: () => import('@/views/groups/GroupsList.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default groups

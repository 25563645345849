const corporates = [
  {
    path: '/corporates',
    name: 'corporates-list',
    component: () => import('@/views/corporates/corporateList/CorporatesList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/corporates/:id',
    name: 'corporate-details',
    component: () => import('@/views/corporates/corporate/Corporate.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vaccine-requests',
    name: 'corporates-requests',
    component: () => import('@/views/requests/list/RequestsList.vue'),
    meta: {
      layout: 'content',
    },
  },
  {
    path: '/vaccine-requests/:id',
    name: 'corporates-requests-view',
    component: () => import('@/views/requests/view/RequestsView.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default corporates

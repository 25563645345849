const others = [
  {
    path: '/documentation',
    name: 'documentation',
    component: () => import('@/views/others/Documentation.vue'),
    meta: {
      layout: 'content',
    },
  },
]

export default others

import { mdiDomainPlus, mdiFileTreeOutline, mdiNotebookPlus } from '@mdi/js'

export default [
  {
    title: 'Facilities',
    icon: mdiDomainPlus,
    children: [
      {
        title: 'List All Facilities',
        icon: mdiFileTreeOutline,
        to: 'facility-list',
      },
      {
        title: 'Vaccine Allocation',
        icon: mdiNotebookPlus,
        to: 'facility-allocation',
      },
    ],
  },
]

import {
  mdiBallotRecount,
  mdiChartBarStacked,
  mdiChartBellCurve,
  mdiChartDonut,
  mdiDotsHexagon,
  mdiMapOutline,
} from '@mdi/js'

export default [
  {
    title: 'Reports',
    icon: mdiChartDonut,
    children: [
      {
        title: 'Reports',
        icon: mdiBallotRecount,
        to: 'reports',
      },
      {
        title: 'Charts',
        icon: mdiChartBellCurve,
        children: [
          {
            title: 'Facility Summary',
            to: 'reports-charts-facility',
          },
          {
            title: 'Corporate Summary',
            to: 'reports-charts-corporates',
          },
        ],
      },
      {
        title: 'Graphs',
        icon: mdiChartBarStacked,
        children: [
          {
            title: 'Facility Summary',
            to: 'reports-graphs-facility',
          },
          {
            title: 'Corporate Summary',
            to: 'reports-graphs-corporates',
          },
        ],
      },
      {
        icon: mdiMapOutline,
        title: 'View Maps',
        to: 'reports-maps',
      },
      {
        title: 'View Metabase',
        icon: mdiDotsHexagon,
        href: 'https://dashboards.kepsa.or.ke',
      },
    ],
  },
]

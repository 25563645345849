import axios from '@axios'

export default {
  namespaced: true,
  state: {
    items: {},
    loading: false,
  },
  getters: {
    items(state) {
      return state.items
    },
  },
  actions: {
    fetchMohRequests(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/moh-request')
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMohRequest(ctx, { id }) {
      return new Promise((resolve, reject) => {
        axios
          .get(`/moh-request/${id}`)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}

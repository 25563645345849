import corporates from './corporates'
import dashboard from './dashboard'
import events from './events'
import facilities from './facilities'
import groups from './groups'
import others from './others'
import reports from './reports'

// Array of sections
export default [...dashboard, ...events, ...corporates, ...facilities, ...groups, ...reports, ...others]
